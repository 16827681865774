








































import { Vue, Component } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from '@/serve/mine'
import { Toast } from 'vant'
@Component({
  components: {
    Nav,
  },
})
export default class Address extends Vue {
  radio = [];
  listData = [];
  created(){
    this.getAddress()
  }
  private goBack(): void {
    this.$router.go(-1);
  }
  private goTo(val: string): void {
      this.$router.push(val)
  }
  async getAddress(){
    const res = await serve.getAddressList({size:9999})
      this.listData = res.data.data
  }
  async delAddress(id: string){
    const res = await serve.deleteAddress(id)
    if(res.data.code === 0 ){
      Toast.success('删除成功')
      this.getAddress()
    }else {
      Toast.fail(res.data.message)
    }
  }
}
